/* .chart-container {
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: block;
  margin: 10px 20px;
  height: 640px;
  padding: 8px 20px;
}*/
/* 
.MuiDataGrid-columnHeader {
  background-color: aliceblue !important;
} */

.PrivateSwitchBase-root-4 {
  height: 56px;
}

.table-attributes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px 15px 33px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  color: #ffffff;
}

/* .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  color: #109cf1;
}
 */
campaign-container{
  height: 100%;
  width: 100%;
}

.MuiDataGrid-columnHeader--moving {
  background-color: transparent!important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px!important;
  }

.MuiDataGrid-root svg.MuiDataGrid-root.MuiDataGrid-iconSeparator {
    color: #ffffff!important;
    opacity: 0;
  }

.MuiDataGrid-root .MuiIconButton-root.Mui-disabled {
  color: #37CAD3;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #FFFFFF!important;
  }
  
  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    background-color: #FFFFFF!important;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.3);
    border-radius: 4px;
  }
  
  .MuiDataGrid-footerContainer{
    border: 0px!important;
  }

  .MuiDataGrid-root{
    border: 0px!important;
    font-family: 'Nunito Sans', 'sans-serif'!important;
  }

  .MuiDataGrid-iconSeparator{
    color: transparent!important;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    /* line-height: 6px; */
    /* identical to box height, or 60% */

    letter-spacing: 0.06em;
    text-transform: uppercase !important;

    color: #595959;
  }

  .MuiTypography-colorPrimary {
    color:#595959 !important;
    font-family: "Nunito Sans";
    font-size: 14px;
  }

  .css-3ihp42-MuiDataGrid-root .MuiDataGrid-cellContent{
    color:#595959 !important
  }
.MuiCheckbox-colorPrimary.Mui-checked {
    color: #21DA8C !important
  }