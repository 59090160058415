.header-container{
    height: 28px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.header{
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 133.4% !important;
    color: #1E4620 !important;
}

.back-header-container{
    display: flex;
    width: auto;
    align-items: center;
}